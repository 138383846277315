import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldAlt, faHandHoldingUsd, faLaptopHouse } from '@fortawesome/free-solid-svg-icons'

import '../styles/features.css'


export const Features = () => {
  return (
    <div className="features">
      <div>
        <>
          <h4>Privacy Protected</h4>
          <FontAwesomeIcon icon={faShieldAlt} color="#FA6C7C" style={{"fontSize":"6.3em"}} />
          <p>Stay anonymous.</p>
        </>
      </div>
      <div>
        <>
          <h4>Secure Payment</h4>
          <FontAwesomeIcon icon={faHandHoldingUsd} color="#FA6C7C" style={{"fontSize":"6.3em"}} />
          <p>Safe payment methods </p>
        </>
      </div>
      <div>
        <>
          <h4>Freedom</h4>
          <FontAwesomeIcon icon={faLaptopHouse} color="#FA6C7C" style={{"fontSize":"6.3em"}} />
          <p>Work from anywhere.</p>
        </>

      </div>
    </div>
  )
}