import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import '../styles/header.css'

export const AppHeader = () => {
  return(
      <header className="App-header">
        <div className="brand">
          <Link to="/">
          <h1>Luxxecammodels</h1>
          <h5>A Virtual studio</h5>
          </Link>
        </div>
        <div className="navigation">
          <ul>
            <li><Link to="/register/">FAQ</Link></li>
            <li><Link to="/register/">Registration</Link></li>
            <li><Link to="/register/">About</Link></li>
            <li><Link to="/register/">Blog</Link></li>
            <li>Login</li>
          </ul>
        </div>
        <div className="navBars">
            <FontAwesomeIcon icon={faBars} style={{"fontSize": "3em"}}/>
        </div>
      </header>
  )
}