import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhotoVideo, faMoneyBillAlt, faClock, faHeadset, faMask } from '@fortawesome/free-solid-svg-icons'
import '../styles/tutorial.css'

export const Tutorial = () => {
  return (
    <div className="tutorial" id='tutorial'>
      <h4>Why choose us?</h4>
      <div className="tutorial-block">
        <div className="explain">
          <h6>It's easy to get started:</h6>
          <ul>
            <li className="explain-item">
              <FontAwesomeIcon icon={faPhotoVideo} color="#FA6C7C" style={{"fontSize":"4.3em"}} />
              <div>
                <p>Shoot and create your content. There is no limit to your creativity.
                Anything from live video chats, photos to  videos.
               </p>
              </div>
            </li>

            <li className="explain-item">
              <FontAwesomeIcon icon={faMoneyBillAlt} color="#FA6C7C" style={{"fontSize":"4.3em"}} />
              <div>
                <p>
                  High income guaranteed. We have one of the highest payout in the industry.
                  You get to keep between 85% to 90% of your earnings.
                </p>
              </div>
            </li>

            <li className="explain-item">
              <FontAwesomeIcon icon={faClock} color="#FA6C7C" style={{"fontSize":"4.3em"}} />
              <div>
                <p>
                  Work at your own schedule. You decide when it's time to clock in and out.
                  You will learn how to reach the your cutomers.
                </p>
              </div>
            </li>

            <li className="explain-item">
              <FontAwesomeIcon icon={faMask} color="#FA6C7C" style={{"fontSize":"4.3em"}} />
              <div>
                <p>
                  Privacy is at the heart of our operation. We ensure that you can work freely
                  and never have to worry about your identity.
                </p>
              </div>
            </li>

            <li className="explain-item">
              <FontAwesomeIcon icon={faHeadset} color="#FA6C7C" style={{"fontSize":"4.3em"}} />
              <div>
                <p>
                  Training and technical support. We are here  so you can maximise your perfomance.
                  We will take of all the technical support needed to get you up and running.
                </p>
              </div>
            </li>
          </ul>
          <div>
            <button>Start today!</button>
          </div>
        </div>
        <div className="winner"></div>
      </div>
    </div>
  )
}