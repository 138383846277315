
import axios from 'axios';
import { Config, domain } from './Conf';

export const fecthUrl = (filename) => {
  const url = "https://ily0uicxb6.execute-api.eu-west-1.amazonaws.com/default/presignedUrl";
  return axios.get(url, {params: {filename}}).then(res => res.data)
}


function consume(progressEvent, setStatus) {
  var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      setStatus(percentCompleted)
}

export const uploadFile = async (featured, presigned, setStatus, clearContent) => {
  const payload = new FormData();
  const imageFile = featured.files[0];
  console.log(presigned);
  Object.keys(presigned.fields).map((key) => {
    payload.append(key, presigned.fields[key]);
    return null;
  });
  payload.append('file', imageFile, presigned.key);
  const conf = {
    headers: {'Content-Type': 'multipart/form-data'},
    onUploadProgress: progressEvent => consume(progressEvent, setStatus),
  }
  return axios.post(`${presigned.url}`, payload, conf)
    .then(data => clearContent());
};




export const signInUrl = () => {
  const queryString = Object.keys(Config).map(key => `${key}=${Config[key]}`).join('&')
  return `${domain}/login?${queryString}`;
}

export const signOutUrl = () => {
  return `${domain}/logout?client_id=${Config.client_id}&redirect_uri=${Config.redirect_uri}&response_type=${Config.response_type}`;
}