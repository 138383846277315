import React from 'react';
import '../styles/newsletter.css';


export const NewsLetter = () => {
  return (
    <div className="newsletter">
      <div className="newsletterInfo">
        <h5>Get the latest</h5>
        <p>Subscribe to our newsletter today!</p>
      </div>
    <form>
      <input type="email" className="email"/>
      <button className='button'>Subscribe</button>
    </form>
    </div>
  );
}