import React, {useState} from 'react';
import { fecthUrl, uploadFile } from '../utils';

export const Upload = () => {
  const [content, setContent] = useState({files: null})
  const [filename, setFilename ] = useState(null);
  const [status, setStatus] = useState(0);
  const generate_url = async (filname) => {
      return fecthUrl(filname);
  }

  const handleFile = (e) => {
    const file = e.target.files[0];
    const blob = new Blob([file], { type: file.type });
    setContent({files: [blob] })
    setFilename(file.name)
  }

  const clearContent = () => {
    const el = document.getElementById('uploadInput');
    el.value = '';
    console.log(el, 'clear');
  }

  const uplodFile = async () => {
    generate_url(filename).then(params =>
      uploadFile(content, params, setStatus, clearContent)
    )
  }

  return (
    <>

    <input id="uploadInput" onChange={(e) => handleFile(e)} type="file" />
    <button onClick={(e) => uplodFile(e)}>Upload</button>
    {status}%
    </>
  )
}