import React, { useState, useEffect } from 'react';
import { PageLayout } from './Layout';

import './styles/app.css'
import './styles/typography.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!userInfo) {
      const params = {}
      const res = window.location.hash;
      if (res !== undefined) {
        res.slice(1).split('&').map(x =>(x.split('='))).map(([k, v]) => (params[k] = v))
        window.localStorage.setItem('auth', JSON.stringify(params))
        setUserInfo(params)
        console.log(params)
      }
    }
  }, [userInfo, setIsLoggedIn, isLoggedIn])
  return (
    <div className="App">
      <PageLayout />
    </div>
  );
}

export default App;
