import React from 'react';
import '../styles/hero.css'

// import girl from '../images/girl.jpg'
export const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-text">
        <h1>Be your own boss</h1>
        <h6>become a webcam model and start earning today</h6>
        <button><a href="#tutorial">Learn how</a></button>
      </div>
    </div>
  )
}