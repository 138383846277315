import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { Home } from '../Pages/Home';
import { Upload } from '../Pages/Upload';
import { Register } from '../Pages/Register';
import { AppHeader } from '../Components/Header';

export const PageLayout = () => {

  return(
    <>
      <Router>
      <AppHeader />
      <main>
        <section className="content">
          <Switch>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/upload">
                <Upload />
            </Route>
            <Route path="/">
                <Home />
            </Route>
          </Switch>
        </section>
      </main>
      </Router>
      <footer></footer>
    </>
  )
}