import React from 'react';
import { Hero } from '../Components/Hero';
import { Features } from '../Components/Features';
import { Tutorial } from '../Components/Tutorial';
import { NewsLetter } from '../Components/Newsletter';

export const Home = () => {
  return (
    <>
    <Hero />
    <Features />
    <NewsLetter />
    <Tutorial />
    </>
  )
}